import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import ScrollButton from "./Components/ScrollButton";
import HomePage from "./Pages/HomePage";
// import AuthPage from "./Pages/AuthPage";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import SubFooter from "./Components/SubFooter";
// import Cart from "./Components/Cart";

function App() {
  return (
    <BrowserRouter className="no-scrollbar overflow-y-auto">
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/cart" element={<Cart />} /> */}
        {/* <Route path="/auth" element={<AuthPage />} /> */}
      </Routes>
      <ScrollButton />
      <Footer />
      <SubFooter />
    </BrowserRouter>
  );
}

export default App;
