import product1 from "../assets/product1.jpg";
import product2 from "../assets/product2.jpg";
import product3 from "../assets/product3.jpg";
import product4 from "../assets/product4.jpg";
import FoxtailKhichdi from "../assets/Recipes/foxtail-millet-khichdi.jpg";
import FoxtailTikki from "../assets/Recipes/foxtail-millet-tikki.jpg";
import UnpolishedDaliya from "../assets/Recipes/unpolished-daliya.jpg";
import UnpolishedKhichdi from "../assets/Recipes/unpolished-khichdi.jpg";
import UnpolishedTikki from "../assets/Recipes/unpolished-tikki.jpg";
import PolishedKhichdi from "../assets/Recipes/polished-khichdi.jpg";
import PolishedHalwa from "../assets/Recipes/polished-halwa.jpg";
import PolishedKheer from "../assets/Recipes/polished-kheer.jpg";
import PolishedTikki from "../assets/Recipes/polished-tikki.jpg";

export const productDataHindi = [
  {
    img: product1,
    cTitle: "Foxtail Millet",
    alt: "फॉक्‍सटेल मिलेट (कंगनी/कांग/काऊन/राल/कावलाई/कोरालू)",
    title: "फॉक्‍सटेल मिलेट (कंगनी/कांग/काऊन/राल/कावलाई/कोरालू)",
    priceHalf: 49,
    priceOne: 99,
    dTitle: "फॉक्सटेल मिलेट (कंगनी/कांग/काऊन/राल/कावलाई/कोरालू)",
    defination: `फॉक्सटेल मिलेट, जिसे हिंदी में "कंगनी" भी कहा जाता है, एक प्रकार का खाद्य अनाज है जो सदियों से एशियाई व्यंजनों का एक महत्वपूर्ण हिस्सा रही है। यह अत्यधिक पौष्टिक अनाज अक्सर खाद्य स्रोत के रूप में उपयोग किया जाता है। सबसे ज़्यादा प्रोटीन और फाइबर की मात्रा होने के कारण यह शरीर को ऊर्जा देने के साथ साथ उसकी पाचन क्षमता को भी ठीक करता है।`,
    benefits: "फॉक्सटेल मिलेट के सेवन के फायदे:",
    dPara: [
      {
        point: "आयरन और कैल्शियम से भरपूर।",
      },
      {
        point:
          "कोलेस्ट्रॉल के स्तर को कम करने में मदद करता है जिससे हृदय स्वास्थ्य बेहतर होता है।",
      },
      {
        point: "गर्भवती महिलाओं के लिए सुपरफूड माना जाता है।",
      },
      {
        point:
          "मैग्नीशियम का अच्छा स्रोत, जो हड्डियों को स्वस्थ बनाए रखने में मदद करता है।",
      },
      {
        point: "वजन कम करता है।",
      },
      {
        point: "तंत्रिका तंत्र को मजबूत करता है।",
      },
    ],
    recipeTitle: "फॉक्सटेल मिलेट (कंगनी) विधि",
    recipes: [
      {
        title: "फॉक्सटेल मिलेट (कंगनी) टिक्की",
        image: FoxtailTikki,
        ingredients: [
          "१ कटोरी पीला मोरधन",
          "३ छोटे आलू",
          "३-४ हरी मिर्च",
          "आधी कटोरी पीसी हुई मूंगफली",
          "नमक/ सेंधा नमक स्वाद अनुसार",
        ],
        instructions: [
          "पीले मोरधन (फॉक्सटेल मिलेट) को ५-६ घंटे पानी में भिगो के रखें।",
          "एक पैन में तेल/ मूंगफली तेल गरम करके उसमे जीरा, हरी मिर्च डालकर पकाये।",
          "पक जाने के बाद उसमे १ कटोरी मोरधन डालकर उसे पकाये।",
          "पक जाने के बाद उसमे ४ कटोरी पानी डालकर थोड़ी देर पकाये।",
          "पानी पूरी तरह से सूख जाने के बाद उसे ठंडा करके उसमे आलू को मैश करके, मूंगफली डाल के छोटी छोटी टिक्की बना लें।",
          "एक फ्राइंग पैन में तेल/ मूंगफली तेल गरम करके टिक्की को फ्राई करले।",
          "हरी चटनी/ टोमेटो सॉस/ दही के साथ गरमा गरम परोसें।",
        ],
      },
      {
        title: "फॉक्सटेल मिलेट (कंगनी) खिचड़ी",
        image: FoxtailKhichdi,
        ingredients: [
          "१ कटोरी पीला मोरधन",
          "२ छोटे आलू",
          "३-४ हरी मिर्च",
          "आधी कटोरी पीसी हुई मूंगफली",
          "नमक/ सेंधा नमक स्वाद अनुसार",
          "आधी छोटी चम्मच जीरा",
        ],
        instructions: [
          "पीले मोरधन (फॉक्सटेल मिलेट) को ५-६ घंटे पानी में भिगो के रखें।",
          "एक कड़ाई में १ चम्मच तेल/ मूंगफली तेल गरम करें।",
          "उसमे जीरा, आलू, हरी मिर्च डालकर पकाये।",
          "नमक/ सेंधा नमक स्वाद अनुसार डाले।",
          "उसमे मोरधन डालकर हल्का पका ले।",
          "उसके बाद उसमे ४ कटोरी पानी डालकर थोड़ी देर पकाये।",
          "पानी पूरी तरह से सूख जाने के बाद उसमे मूंगफली के दाने और हरा धनिया डालकर ५-१० मिनट के लिए ढक कर रख दे।",
          "गरमा गरम सर्व करें।",
        ],
      },
    ],
  },
  {
    img: product2,
    cTitle: "Little Millet (Unpolished)",
    alt: "लिटिल मिलेट (अनपॉलिश्ड)/ “कुटकी अनपॉलिश्ड” (कुटकी, मोरैयो, शावन/ सामा/ समाई/ सवा, हलवी,वरी)",
    title: "लिटिल मिलेट (अनपॉलिश्ड) / “कुटकी अनपॉलिश्ड” (कुटकी, मोरैयो, शावन/ सामा/ समाई/ सवा, हलवी,वरी)",
    priceHalf: 49,
    priceOne: 99,
    dTitle:
      "लिटिल मिलेट (अनपॉलिश्ड)/ “कुटकी अनपॉलिश्ड” (कुटकी,मोरैयो,शावन/ सामा/ समाई/ सवा,हलवी,वरी)",
    defination: `इसे हिंदी में  “कुटकी” के नाम से भी जाना जाता है। भारत में इसकी पहचान 'उपवास के अनाज' या 'उपवास के चावल' के रूप में की जाती है। परंपरागत रूप से, शिशुओं को पहला ठोस आहार 'अन्नप्राश' के नाम से भी जाना जाता है। इसमें आयरन और ऊर्जा की मात्रा सबसे अधिक होने के कारण इसे बीमारी से उबरने के लिए सबसे अच्छा भोजन भी माना जाता है।`,
    benefits: "लिटिल मिलेट (अनपॉलिश्ड) के सेवन के फायदे:",
    dPara: [
      {
        point: "शक्तिशाली एंटीऑक्सीडेंट से भरपूर।",
      },
      {
        point: "मधुमेह का खतरा कम करें।",
      },
      {
        point: "श्वसन स्थितियों में सुधार करने में मदद करता है।",
      },
      {
        point: `मोटापे को नियंत्रित करता है।`,
      },
      {
        point: `हृदय प्रणाली का प्रबंधन करता है।`,
      },
    ],
    recipeTitle: "लिटिल मिलेट (अनपॉलिश्ड) विधि",
    recipes: [
      {
        title: "अनपॉलिश्ड लिटिल मिलेट (अनपॉलिश्ड मोरधन/ कुटकी अनपॉलिश्ड/ समा/ सवा) टिक्की",
        image: UnpolishedTikki,
        ingredients: [
          "एक कटोरी लिटिल मिलेट अनपॉलिश्ड",
          "२-३ छोटे आलू ",
          "मूंगफली के दाने बारीक पिसे हुए",
          "हरी मिर्च कटी हुई",
          "नमक/ सेंधा नमक",
        ],
        instructions: [
          "पैन में एक चम्मच तेल/ मूंगफली तेल डालें।",
          "तेल गरम होने के बाद उसमे जीरा, हरी मिर्च, पीसी हुई मूंगफली के दाने डालकर हल्का पका लें।",
          "अब १ कटोरी मोरधन डालकर हल्का भूनें।",
          "३ कटोरी पानी डालकर उसे थोड़ी देर के लिए पका लें।",
          "पानी पूरी तरह सूख जाने के बाद पके हुए मोरधन को ठंडा करलें।",
          "मोरधन ठंडा हो जाये तब उसमे आलू मैश करके डालें और उसकी छोटी छोटी टिक्की बना लें।",
          "एक फ्राइंग पैन में तेल/ मूंगफली तेल गरम करें।",
          "टिक्की को उसमे पकने तक फ्राई करें।",
          "हरी चटनी/ टोमेटो सॉस/ दही के साथ गरमा गरम परोसें।",
        ],
      },
      {
        title:
          "अनपॉलिश्ड लिटिल मिलेट (अनपॉलिश्ड मोरधन/ कुटकी अनपॉलिश्ड/ समा/ सवा) खिचड़ी",
        image: UnpolishedKhichdi,
        ingredients: [
          "एक कटोरी अनपॉलिश्ड मोरधन",
          "हरी मिर्च",
          "मूंगफली के दाने बारीक़ पिसे हुए",
          "नमक/ सेंधा नमक जीरा ",
        ],
        instructions: [
          "एक पैन मे तेल/ मूंगफली तेल गरम करके उसमे जीरा, आलू, हरी मिर्च, नमक/ सेंधा नमक डालकर पकाएंगे।",
          "उसके बाद उसमे १ कटोरी मोरधन डालकर उसे हल्का भूनेंगे।",
          "उसके बाद उसमे ३ कटोरी पानी डालकर पकाएंगे।",
          "पानी पूरी तरह से सूख जाने के बाद पीसी हुई मूंगफली और हरा धनिया डालकर गरमा गरम सर्व करेंगे।",
        ],
      },
      {
        title:
          "अनपॉलिश्ड लिटिल मिलेट (अनपॉलिश्ड मोरधन/ कुटकी अनपॉलिश्ड/ समा/ सवा) दलिया",
        image: UnpolishedDaliya,
        ingredients: [
          "एक कटोरी अनपॉलिश्ड मोरधन",
          "घी",
          "गुड़/ शक्कर",
          "ड्राईफ्रूट",
        ],
        instructions: [
          "एक पैन मे घी गरम करेंगे।",
          "घी गरम होने के बाद उसमे मोरधन डालेंगे और उसे सेकेंगे।",
          "मोरधन सिक जाने के बाद उसमे ३ कटोरी पानी डालेंगे।",
          "पानी पूरी तरह से सूख जाने के बाद उसमे गुड़/ शक्कर डालकर पकाएंगे।",
          "मोरधन पक जाने के बाद ड्राई फ्रूट डालकर गरमा गरम सर्व करेंगे।",
        ],
      },
    ],
  },
  {
    img: product3,
    cTitle: "Little Millet (Polished)",
    alt: "लिटिल मिलेट (पॉलिश्ड)/ “कुटकी पॉलिश्ड” (कुटकी,मोरैयो,शावन/ सामा/ समाई/ सवा,हलवी,वरी)",
    title:
      "लिटिल मिलेट (पॉलिश्ड)/ “कुटकी पॉलिश्ड” (कुटकी, मोरैयो, शावन/ सामा/ समाई/ सवा,हलवी,वरी)",
      priceHalf: 55,
      priceOne: 110,
    dTitle:
      "लिटिल मिलेट (पॉलिश्ड)/ “कुटकी पॉलिश्ड” (कुटकी,मोरैयो,शावन/ सामा/ समाई/ सवा,हलवी,वरी)",
    defination: `इसे भी हिंदी में “कुटकी” के नाम से जाना जाता है। इसका उत्पादन लिटिल मिलेट (अनपॉलिश्ड) की परतो को निकालकर किया जाता है। भारत में इसकी पहचान ‘उपवास के अनाज’ या ‘उपवास के चावल’ के रूप में की जाती है। कैल्शियम और प्रोटीन की मात्रा सबसे अधिक होने के कारण इसे बच्चो और बड़ो के लिए सेहतमंद माना जाता है।',
  benefits: "लिटिल मिलेट (पॉलिश्ड) के सेवन के फायदे:`,
    dPara: [
      {
        point: "सफेद चावल की तुलना में 3 गुना अधिक आहारीय फाइबर।",
      },
      {
        point: "सहनशक्ति और स्वस्थ रक्त उत्पादन के निर्माण में मदद करता है।",
      },
      {
        point: "विकास और प्रतिरक्षा में मदद करता है।",
      },
      {
        point: "शरीर को ऊर्जा चयापचय में मदद करता है।",
      },
      {
        point:
          "बी1 और फोलेट जैसे विटामिन से भरपूर जो आरबीसी(रेड ब्लड सेल्स) को नियंत्रण में रखते है।",
      },
    ],
    recipeTitle: "लिटिल मिलेट (पॉलिश्ड) विधि",
    recipes: [
      {
        title: "सफ़ेद मोरधन (लिटिल मिलेट/ कुटकी पॉलिश्ड/ समा/ सवा) टिक्की",
        image: PolishedTikki,
        ingredients: [
          "१ कटोरी सफ़ेद मोरधन",
          "२-३ छोटे आलू ",
          "मूंगफली के दाने बारीक पिसे हुए",
          "हरी मिर्च कटी हुई",
          "नमक/ सेंधा नमक",
        ],
        instructions: [
          "पैन में एक चम्मच तेल/ मूंगफली तेल डालें।",
          "तेल गरम होने के बाद उसमे जीरा, हरी मिर्च, पीसी हुई मूंगफली के दाने डालकर हल्का पका लें।",
          "अब १ कटोरी मोरधन डालकर हल्का भूनें।",
          "३ कटोरी पानी डालकर उसे थोड़ी देर के लिए पका लें।",
          "पानी पूरी तरह सूख जाने के बाद पके हुए मोरधन को ठंडा करलें।",
          "मोरधन ठंडा हो जाये तब उसमे आलू मैश करके डालें और उसकी छोटी छोटी टिक्की बना लें।",
          "एक फ्राइंग पैन में तेल/ मूंगफली तेल गरम करें।",
          "टिक्की को उसमे पकने तक फ्राई करें।",
          "हरी चटनी/ टोमेटो सॉस/ दही के साथ गरमा गरम परोसें।",
        ],
      },
      {
        title: "सफ़ेद मोरधन (लिटिल मिलेट/ कुटकी पॉलिश्ड/ समा/ सवा) खीर",
        image: PolishedKheer,
        ingredients: [
          "१ कटोरी सफ़ेद मोरधन",
          "दूध, शक्कर",
          "इलाइची पाउडर",
          "काजू",
          "बादाम",
          "किशमिश ",
        ],
        instructions: [
          "एक बर्तन में दूध लेकर उसे उबाल लें।",
          "उसमे मोरधन डालकर उसे पका लें।",
          "मोरधन पाक जाए तो उसमे शक्कर डालकर थोड़ी देर और पकाएं।",
          "पक जाने के बाद उसमे काजू, बादाम, किशमिश, डालकर गरमा गरम सर्व करें।",
        ],
      },
      {
        title: "सफ़ेद मोरधन (लिटिल मिलेट/ कुटकी पॉलिश्ड/ समा/ सवा) खिचड़ी",
        image: PolishedKhichdi,
        ingredients: [
          "१ कटोरी सफ़ेद मोरधन",
          "१ आलू (बारीक कटा हुआ)",
          "मूंगफली (बारीक पीसी हुई)",
          "आधी कटोरी पीसी हुई मूंगफली",
          "हरी मिर्च कटी हुई",
          "नमक/ सेंधा नमक",
        ],
        instructions: [
          "पैन में एक चम्मच तेल/ मूंगफली तेल डालें।",
          "तेल गरम होने के बाद उसमे जीरा, कटा हुआ आलू पकाये।",
          "पक जाने के बाद उसमे १ कटोरी मोरधन डालकर थोड़ी देर भूनें।",
          "अब ३ कटोरी पानी डालकर उसे पकाये।",
          "पानी पूरी तरह सूख जाये तब उसमे पीसी हुई मूंगफली डाल दे।",
          "उस पर हरा धनिया डाल कर सर्व करें।",
        ],
      },
      {
        title: "सफ़ेद मोरधन (लिटिल मिलेट/ कुटकी पॉलिश्ड/ समा/ सवा) हलवा",
        image: PolishedHalwa,
        ingredients: [
          "१ कटोरी सफ़ेद मोरधन",
          "दूध",
          "शक्कर",
          "घी",
          "इलाइची",
          "केसर",
          "काजू",
          "बादाम",
          "किशमिश ",
        ],
        instructions: [
          "एक पैन में चम्मच घी लेंगे।",
          "घी गरम हो जाने के बाद उसमे काजू, बादाम, किशमिश डालकर हल्का फ्राई करें और फिर उन्हें एक अलग बर्तन में निकल लें।",
          "मोरधन को एक मिक्सर में पीस कर दरकारा कर लें।",
          "बचे हुए घी में पिसे हुए मोरधन को सेक लें।",
          "मोरधन सिक जाने के बाद उसमे ३ कटोरी पानी डालकर उसे पकाएं।",
          "पानी सूख जाने के बाद उसमे शक्कर डालकर अच्छे से मिलाएं।",
          "एक बर्तन में दूध गरम करके उसमे केसर और हलवे (६) को डालें और पकाएं।",
          "पक जाने के बाद उसपे ड्राईफ्रूट डालकर उसे गरमा गरम परोसें।",
        ],
      },
    ],
  },
  {
    img: product4,
    cTitle: "Unpolished Kodo Millet",
    alt: "जैविक कोदो मिलेट (अनपॉलिशड)/ कोड़ों, कोड़रा, हरका, वारकु",
    title:
      "जैविक कोदो मिलेट (अनपॉलिशड)/ कोड़ों, कोड़रा, हरका, वारकु",
      priceHalf: 49,
      priceOne: 89,
    dTitle:
      "जैविक कोदो मिलेट (अनपॉलिशड)/ कोड़ों, कोड़रा, हरका, वारकु",
    defination: `कोदो मिलेट लगभग 3000 साल पहले भारत में  से मौजूद था। यह अत्यधिक कठोर, सूखा प्रतिरोधी माना जाता है और पथरीली या बजरी वाली मिट्टी पर उगता है जो अन्य फसलों के लिए उपयुक्त नहीं होती। इसकी अवधि अपेक्षाकृत लंबी है, जिसे परिपक्व होने के लिए चार से छह महीने की आवश्यकता होती है, जबकि अन्य मीलों के लिए दो से चार महीने की आवश्यकता होती है। अब कम अवधि वाली किस्में विकसित हो चुकी हैं। यह एक वार्षिक गुच्छेदार घास है जो 90 सेमी तक ऊँची होती है। दाने का रंग हल्के लाल से लेकर गहरे भूरे तक हो सकता है। इसमें सबसे अधिक आहार फाइबर है।`,
    dPara: [
      {
        point: "कब्ज से बचाता है।",
      },
      {
        point: "तंत्रिका तंत्र को मजबूत बनाता है।",
      },
      {
        point: "रक्त शर्करा नियंत्रण में मदद करता है।",
      },
      {
        point: "किसी भी चयापचय रोग से पीड़ित रजोनिवृत्ति के बाद की महिलाओं के लिए फायदेमंद।",
      },
    ],
    recipeTitle: "कोदो मिलेट (अनपॉलिशड) विधि (जल्द आ रही है...)",
  },
];
