import React, { useContext } from "react";
import Right from "../assets/img.png";
import { AppContext } from "../context/AppContext";

const HeroSection = () => {
  const { isHindi } = useContext(AppContext)
  return (
    <div className="bg-yellow-100 pb-6">
      <div className="w-11/12 mx-auto max-w-[1280px]">
        <div className="w-[100%] justify-between flex flex-col-reverse md:flex-row gap-14 md:gap-0 items-center py-[2.5rem]">
          {/* Left side */}
          <div className="w-full text-2xl items-center md:items-start sm:text-3xl lg:text-[1.75rem] xl:text-4xl flex flex-col justify-center font-semibold gap-4 text-yellow-600">
            <p>{isHindi ? '"स्वस्थ संतुलित हो आहार' : '"Delivering Health'}</p>
            <p className="lg:ml-[9.5rem] xl:ml-[12rem]">
              {isHindi ? 'ऊर्जा दे शरीर को अपार"' : 'Delivering Happiness"'}
            </p>
            <h3 className="text-xl text-yellow-700 px-2">{isHindi ? 'मिलेट क्या है?' : 'What is Millet?'}</h3>
            <p className="text-base text-yellow-700 px-2">{isHindi ? `"मिलेट (मोरधन) एक खाद्य पदार्थ है जिसकी प्रवृति जैविक (ऑर्गेनिक) होती है। इसका सेवन उपवास में भी किया जा सकता है। इनकी पौष्टिकता के कारण इन्हे सुपरफूड भी कहा जाता है।"` : `"Millet (Mordhan) is a food item which tends to be organic. It can be consumed even during fasting/ 'Upwas'. Due to their nutritional value, they are also called superfoods."`}</p>
          </div>

          {/* Right side */}
          <div className="w-full flex justify-center items-center">
            <img src={Right} alt="millet pic" width={600} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
