import React from 'react'
import HeroSection from '../Components/HeroSection'
import Products from '../Components/Products'
import ValueTable from '../Components/ValueTable'
import MilletGov from '../Components/MilletGov'
import Reviews from '../Components/Reviews'
import Recipes from '../Components/Recipes'

const HomePage = () => {
  return (
    <div>
      <HeroSection />
      <Products />
      <Recipes />
      <MilletGov />
      <ValueTable />
      <Reviews />
    </div>
  )
}

export default HomePage
