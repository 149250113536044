import { createContext, useState } from "react";



export const AppContext = createContext();


export default function AppContextProvider({ children }) {
    const [isHindi, setIsHindi] = useState(true);
    const [isLoginPage, setIsLoginPage] = useState(true)
    const value = {
        isHindi,
        setIsHindi,
        isLoginPage,
        setIsLoginPage,
    }

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    )
}