import React, { useContext } from "react";
import { tableData } from "../Data/TableData";
import { AppContext } from "../context/AppContext";

const ValueTable = () => {
  const { isHindi } = useContext(AppContext);
  return (
    <div className="bg-yellow-100 py-10">
      <div className="w-11/12 mx-auto max-w-[1280px]">
      <h2 className="text-3xl md:text-5xl mx-auto text-center font-bold text-yellow-600">
      {isHindi ? "मिलेट की पोषण संबंधी जानकारी" : "Nutritional Information of Millets"}
      </h2>
      <div className="bg-yellow-600 h-1 w-28 rounded-full mt-6 mx-auto"></div>
        <div className="w-[100%] py-[2.5rem] overflow-x-scroll">
          <table className="w-full">
          <caption className="caption-top	mb-2 font-semibold">
            {isHindi ? (<div>
              <p>पोषक मूल्य का स्रोत - स्रोत:</p>
              <p>राष्ट्रीय पोषण संस्थान, 'एन आई एन' - 2017;</p>
              <p>भारतीय खाद्य पदार्थों का पोषक मूल्य, 'एन आई एन' - 2007, 'यू एस डी ए' (2019)</p>
              <p>(प्रति 100 ग्राम)</p>
            </div>) : (<div>
              <p>Source of Nutritive Value - Source:</p>
              <p>Indian Food Composition Tables, NIN - 2017;</p>
              <p>Nutritive Value of Indian Foods, NIN - 2007, USDA (2019)</p>
              <p>(Per 100 gms)</p>
            </div>)}
          </caption>
            <thead>
              <tr className="bg-yellow-300 text-xl text-gray-800 ">
                <th className="py-2"></th>
                <th className="py-2">{isHindi ? "फॉक्‍सटेल मिलेट" : "Foxtail Millet"}</th>
                <th className="py-2">{isHindi ? "लिटिल मिलेट (अनपॉलिश्ड)" : "Little Millet (Unpolished)"}</th>
                <th className="py-2">{isHindi ? "लिटिल मिलेट (पॉलिश्ड)" : "Little Millet (Polished)"}</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((data, index) => (
                <tr className="even:bg-gray-100" key={index}>
                  <td className="py-2 px-4">{isHindi ? data.hTitle : data.eTitle}</td>
                  <td className="py-2 px-4 text-center">{data.data1}</td>
                  <td className="py-2 px-4 text-center">{data.data2}</td>
                  <td className="py-2 px-4 text-center">{data.data3}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ValueTable;