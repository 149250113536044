export const tableData = [
  {
    eTitle: "Energy (Kcal)",
    hTitle: "ऊर्जा (किलो कैलोरी)",
    data1: 331,
    data2: 360,
    data3: 346,
  },
  {
    eTitle: "Protein (gm)",
    hTitle: "प्रोटीन (ग्राम)",
    data1: "12.3",
    data2: "10.1",
    data3: "10.1",
  },
  {
    eTitle: "Carbs (gm)",
    hTitle: "कार्ब्स (ग्राम)",
    data1: "60.1",
    data2: "65.5",
    data3: "65.5",
  },
  {
    eTitle: "Fibre (mg)",
    hTitle: "फाइबर (मिलीग्राम)",
    data1: "14",
    data2: "22.1",
    data3: "7.7",
  },
  {
    eTitle: "Calcium (mg)",
    hTitle: "कैल्शियम (मिलीग्राम)",
    data1: "31",
    data2: "16.1",
    data3: "16.1",
  },
  {
    eTitle: "Iron (mg)",
    hTitle: "आयरन (मिलीग्राम)",
    data1: "2.8",
    data2: "1.2",
    data3: "1.2",
  },
];
