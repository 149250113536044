import React, { useContext, useState } from "react";
import "../App.css";
import { BsWhatsapp } from "react-icons/bs";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ReactWhatsapp from "react-whatsapp";
import { AppContext } from "../context/AppContext";
import { AiOutlineArrowDown } from "react-icons/ai";
import { useCart } from "../context/Cart";
import toast from "react-hot-toast";

const Product = (props) => {
  const cart = useCart();
  // console.log(cart);
  const data = props.item;
  const [open, setOpen] = useState(false);
  const [recipeOpen, setRecipeOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const { isHindi } = useContext(AppContext);

  const scrollTo = () => {
    const section = document.querySelector("#scroll-recipe");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const oneKgHandler = async () => {
    cart.items.forEach((element) => {
      console.log(element);

      for (const each in element) {
        // console.log(each);

        if (cart.items[each]) {
          cart.items[each] = cart.items[each] + element[each];
        } else {
          cart.items[each] = element[each];
        }
      }
    });
    cart.setItems([
      ...cart.items,
      {
        title: data?.cTitle,
        price: 180,
        weight: "1000",
      },
    ]);
    toast.success("Add 1000kg");
  };
  console.log(cart.items);
  return (
    <>
      <div className="w-full border rounded-xl shadow-xl hover:shadow-2xl p-4 flex flex-col gap-8 md:hover:scale-105 transition-all duration-300">
        <img
          src={data?.img}
          alt={data?.alt}
          className="border-none mx-auto bg-cover w-[300px] h-[300px] rounded-lg shadow-lg"
        />
        {isHindi ? (
          <div className="h-[4.5rem] md:h-[5rem] lg:h-[6rem]">
            <h4 className="text-xl font-bold text-[#f8a128]">
              जैविक {data?.title}
            </h4>
          </div>
        ) : (
          <h4 className="text-xl font-bold text-[#f8a128]">
            Organic {data?.title}
          </h4>
        )}

        <p className="text-slate-800 text-base font-bold -mb-4 flex items-center w-full">
          <span className="text-green-700 text-xl">₹{data?.priceHalf}/- </span>{" "}
          {isHindi ? "500 ग्राम (पिक अप)" : "500 gm (Pick up)"}
          {/* <button className="relative group ml-auto mr-1">
            <BsFillCartPlusFill
              fontSize={30}
              className="text-green-700"
              onClick={() => {
                cart.setItems([
                  ...cart.items,
                  {
                    title: data?.cTitle,
                    price: 150,
                    weight: "500",
                  },
                ]);
                toast.success("Add 500gm");
              }}
            />
            <span className="hidden group-hover:block transition-all duration-800 group-hover:animate-bounce absolute bg-green-600 w-24 rounded-full text-sm text-white top-3 p-1 right-14 span">
              Add to Cart
            </span>
          </button> */}
        </p>
        <p className="text-slate-800 text-base font-bold -mb-4 flex items-center w-full">
          <span className="text-green-700 text-xl">₹{data?.priceOne}/- </span>{" "}
          {isHindi ? "प्रति किलो (पिक अप)" : "per kg (Pick up)"}
          {/* <button className="relative group ml-auto mr-1">
            <BsFillCartPlusFill
              fontSize={30}
              className="text-green-700"
              onClick={oneKgHandler}
            />
            <span className="hidden group-hover:block transition-all duration-800 group-hover:animate-bounce absolute bg-green-600 w-24 rounded-full text-sm text-white top-3 p-1 right-14 span">
              Add to Cart
            </span>
          </button> */}
        </p>
        <div className="flex flex-row justify-between">
          <button
            className="border-2 rounded-lg p-2 px-3 md:px-1 lg:px-3 text-gray-600 font-semibold"
            onClick={onOpenModal}
          >
            {isHindi ? "अधिक जानें" : "View Details"}
          </button>
          <p
            className="underline p-2 px-3 md:px-1 lg:px-3 text-gray-600 font-semibold flex justify-center items-center cursor-pointer"
            onClick={scrollTo}
          >
            {isHindi ? "बनाने की विधि" : "Recipes"}
            <AiOutlineArrowDown className="text-2xl" />
          </p>
        </div>
        {/* <div>
          <div className="flex bg-green-800 text-white gap-2 justify-center items-center rounded-md p-1 px-2 md:px-1 lg:px-2">
            <ReactWhatsapp
              number="+91 7000106748"
              message=""
              className="text-lg md:text-sm lg:text-lg font-semibold"
            >
              {isHindi ? " ऑर्डर करें" : "Order Now"}
            </ReactWhatsapp>
            <BsWhatsapp className="text-xl" />
          </div>
        </div> */}
      </div>

      {recipeOpen && (
        <>
          <Modal
            open={recipeOpen}
            onClose={() => setRecipeOpen(false)}
            center
            animationDuration={500}
            aria-labelledby="my-modal-title"
            aria-describedby="my-modal-description"
          >
            <h2
              className="text-xl font-semibold text-yellow-600 my-3"
              id="my-modal-title"
            >
              {data?.recipeTitle}
            </h2>
            <div className="h-[0.15rem] rounded-full mx-18 my-4 bg-yellow-300"></div>
            {data?.recipes?.map((recipe, index) => (
              <div key={index} id="my-modal-description">
                <h2 className="text-xl font-semibold text-orange-500 mt-8">
                  {recipe?.title}
                </h2>
                <h4 className="text-slate-600 tracking-wider mt-3">
                  <span className="font-semibold">
                    {isHindi ? "सामग्री" : "Ingredients"} -{" "}
                  </span>
                  <span>{recipe?.ingredients + " "}</span>
                </h4>
                <h5 className="text-slate-700 font-semibold mt-8">
                  {isHindi ? "बनाने की विधि:" : "Instructions:"}
                </h5>

                {recipe?.instructions?.map((e, index) => (
                  <ul className="list-inside" key={index}>
                    <li className="list-disc my-3 text-slate-500 leading-8">
                      {e}
                    </li>
                  </ul>
                ))}
              </div>
            ))}
          </Modal>
        </>
      )}

      {open && (
        <>
          <Modal
            open={open}
            onClose={onCloseModal}
            center
            animationDuration={500}
            aria-labelledby="my-modal-title"
            aria-describedby="my-modal-description"
          >
            <h2
              className="text-xl font-semibold text-yellow-600"
              id="my-modal-title"
            >
              {data?.dTitle}
            </h2>
            <div className="h-[0.15rem] rounded-full mx-auto my-4 bg-yellow-300"></div>
            <div id="my-modal-description">
              <h4 className="text-slate-600">{data?.defination}</h4>
              <h5 className="text-slate-700 font-semibold mt-3">
                {data?.benefits}
              </h5>
              {data?.dPara?.map((e, index) => (
                <ul className="list-inside" key={index}>
                  <li className="list-disc my-5 text-slate-500 leading-8">
                    {e?.point}
                  </li>
                </ul>
              ))}
              <div className="flex bg-green-800 text-white gap-2 w-36 items-center rounded-md p-1 px-2">
                <ReactWhatsapp
                  number="+91 7000106748"
                  message=""
                  className="text-lg font-semibold"
                >
                  Order Now
                </ReactWhatsapp>
                <BsWhatsapp className="text-xl" />
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default Product;
