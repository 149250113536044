import React, { useContext } from "react";
import Product from "./Product";
import { productDataHindi } from "../Data/ProductDataHindi";
import { productDataEnglish } from "../Data/ProductDataEnglish";
import { AppContext } from "../context/AppContext.js";
import { BiLogoWhatsappSquare } from "react-icons/bi";

const Products = () => {
  const { isHindi } = useContext(AppContext);
  const productData = isHindi ? productDataHindi : productDataEnglish;
  return (
    <div className="w-11/12 mx-auto max-w-[1280px] py-12">
      <div className="w-[100%] justify-between flex flex-col gap-14 md:gap-0 items-center py-[2.5rem]">
        <h2 className="text-5xl mx-auto text-center font-bold text-yellow-600">
          {isHindi ? "मिलेट के प्रकार" : "Types of Millet"}
        </h2>
        <div className="bg-yellow-600 h-1 w-28 rounded-full mt-6"></div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-content-center mt-10 gap-10">
          {productData?.map((item, index) => (
            <Product key={index} item={item} />
          ))}
        </div>
        <div className="flex justify-center items-center flex-wrap text-center mt-4 md:mt-20  p-1 px-2 animate-bounce text-xl font-bold text-[#f8a128] border-2 border-[#f8a128] rounded-2xl">
          <BiLogoWhatsappSquare
            fontSize={50}
            className="text-green-600"
          />
          <p className="font-semibold">
          {isHindi ? "बल्क ऑर्डर के लिए संपर्क करें" : "For bulk orders contact"} -{" "}
          &nbsp;
          <a
            href="tel:+917000106748"
            className="font-semibold underline"
          >
            +91 70001-06748
          </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Products;
