import React from "react";

const SubFooter = () => {
  return (
    <div className="bg-[#291111]">
      <div className="w-11/12 mx-auto max-w-[1280px]">
        {/* <div className="w-[100%] py-2 text-white text-sm flex flex-col md:flex-row justify-center gap-3 items-center md:justify-between"> */}
        <div className="w-[100%] py-4 md:py-2 text-white text-sm flex justify-center items-center">
          <p>@2024, All Rights Reserved (Images & Content)©</p>
          {/* <div className="flex justify-center items-center flex-wrap text-center">
            <p className="text-sm">Powered By Hariom Chouhan - </p>
            &nbsp;
            <a href="tel:9770994821" className="font-semibold">
              +91 97709-94821
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SubFooter;
