import React, { useContext } from "react";
import GovImage from "../assets/millet-gov.jpg";
import { AppContext } from "../context/AppContext";

const MilletGov = () => {
  const { isHindi } = useContext(AppContext);
  return (
    <div className="flex flex-col justify-center items-center my-20">
      <h2 className="text-3xl md:text-5xl mx-auto text-center font-bold text-yellow-600">
      {isHindi ? "अंतर्राष्ट्रीय मिलेट वर्ष 2023" : "International Year of Millets 2023"}
      </h2>
      <div className="bg-yellow-600 h-1 w-28 rounded-full my-6"></div>
      <img src={GovImage} alt="international year of millets 2023" />
    </div>
  );
};

export default MilletGov;
