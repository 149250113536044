import React, { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import Arrow from "../assets/Recipes/arrow.png";
import { productDataHindi } from "../Data/ProductDataHindi";
import { productDataEnglish } from "../Data/ProductDataEnglish";

const Recipes = () => {
  const { isHindi } = useContext(AppContext);
  const data = isHindi ? productDataHindi : productDataEnglish;
  return (
    <div className="bg-yellow-100" id="scroll-recipe">
      <div className="w-11/12 mx-auto max-w-[1280px] py-12" id="scroll-recipe">
        <div className="p-4 w-[100%] justify-between flex flex-col gap-14 md:gap-0 items-center py-[2.5rem]">
          <h2 className="text-5xl mx-auto text-center font-bold text-yellow-600">
            {isHindi ? "बनाने की विधि" : "Recipes"}
          </h2>
          <div className="bg-yellow-600 h-1 w-28 rounded-full my-2 md:my-8"></div>
          <div className="w-full">
            <Accordion transition transitionTimeout={200}>
              {data?.map((recipe, index) => (
                <AccordionItem key={index} header={isHindi ? "जैविक " + recipe?.recipeTitle : "Organic " + recipe?.recipeTitle}>
                  <div>
                    <h2 className="text-xl font-semibold text-yellow-700 my-3 mt-9">
                      {isHindi ? "जैविक " + recipe?.title : "Organic " + recipe?.title}
                    </h2>
                    {recipe?.recipes?.map((recipe, index) => (
                      <div key={index}>
                        <h2 className="text-xl font-semibold text-orange-500 mt-8">
                          {isHindi ? "जैविक " + recipe?.title : "Organic " + recipe?.title}
                        </h2>
                        <img
                          src={recipe?.image}
                          alt={recipe?.title}
                          className="h-64 w-64 rounded-xl shadow-xl mt-4 hover:scale-105 transition-all duration-200"
                        />
                        <h4 className="text-slate-700 tracking-wider mt-3">
                          <span className="font-semibold">
                            {isHindi ? "सामग्री" : "Ingredients"} -{" "}
                          </span>
                        </h4>
                        <span className="text-slate-500 tracking-wider mt-3 font-medium">
                          {recipe?.ingredients + " "}
                        </span>
                        <h5 className="text-slate-700 font-semibold mt-8">
                          {isHindi ? "बनाने की विधि:" : "Instructions:"}
                        </h5>
                        {recipe?.instructions?.map((instruction, index) => (
                          <ul className="list-inside" key={index}>
                            <li className="list-disc my-3 text-slate-500 leading-8">
                              {instruction}
                            </li>
                          </ul>
                        ))}
                      </div>
                    ))}
                  </div>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recipes;

const AccordionItem = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={({ state: { isEnter } }) => (
      <>
        {header}
        <img
          className={`ml-auto transition-transform duration-200 ease-out ${
            isEnter && "rotate-180"
          }`}
          src={`${Arrow}`}
          width={30}
          alt="arrow icon"
        />
      </>
    )}
    className="border-b text-lg font-semibold text-yellow-600"
    buttonProps={{
      className: ({ isEnter }) =>
        `flex w-full p-4 text-left hover:bg-slate-200 ${
          isEnter && "bg-slate-200"
        }`,
    }}
    contentProps={{
      className: "transition-height duration-200 ease-out",
    }}
    panelProps={{ className: "p-4" }}
  />
);
