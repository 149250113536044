import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/logo.png";
import ReactWhatsapp from "react-whatsapp";
import { BiLogoWhatsappSquare } from "react-icons/bi";
import { AppContext } from "../context/AppContext.js";
import MSME from "../assets/msme-logo.png";
import SatyamevJayate from "../assets/satyamev-jayate-logo.png";
const Header = () => {
  const { isHindi, setIsHindi } = useContext(AppContext);

  const languageHandler = () => {
    setIsHindi((prev) => !prev); // Toggle the boolean value
  };
  return (
    <>
      <div className="w-[100%] h-42 flex flex-col items-center shadow-md bg-[#ffae3c]">
        <div className=" w-full h-6 relative ">
          <div className="w-full mx-auto flex justify-center gap-0 md:gap-5 items-center fixed bg-yellow-400 py-1 rounded-b-3xl">
            <img src={SatyamevJayate} alt="Satyamev Jayate logo" width={30} />
            {/* <p className="text-center text-xs md:text-sm animate-pulse text-green-700"> */}
            <p className="text-center text-xs md:text-sm animate-pulse text-green-700 animate-text bg-gradient-to-r from-red-600 via-blue-500 to-green-700 bg-clip-text text-transparent font-black">
              Udyam Registration No.:{" "}
              <span className="font-semibold text-xs md:text-base">
                UDYAM-MP-23-0108747
              </span>
            </p>
            <img src={MSME} alt="MSME logo" width={50} />
          </div>
        </div>
        <div className="w-11/12 mx-auto max-w-[1280px] mt-7 flex justify-between items-center">
          <Link to="/">
            <img src={Logo} alt="LOGO" width={100} />
          </Link>
          <div className="flex justify-center items-center gap-5">
            <ReactWhatsapp
              number="+91 7000106748"
              message=""
              className="relative group"
            >
              <BiLogoWhatsappSquare
                fontSize={50}
                className="text-green-600 animate-bounce"
              />
              <span className="hidden group-hover:block transition-all duration-800 group-hover:animate-bounce absolute bg-green-600 w-24 rounded-full text-sm text-white top-3 p-1 right-14 span">
                Order Now
              </span>
            </ReactWhatsapp>
            <button
              onClick={languageHandler}
              className="p-1 rounded-md border-yellow-600 text-white px-2 font-semibold w-20 text-lg border-2"
            >
              {!isHindi ? "हिंदी" : "English"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
