import React, { useContext } from "react";
import Review1 from "../assets/Reviews/r1.jpg";
import Review2 from "../assets/Reviews/r2.jpg";
import Review3 from "../assets/Reviews/r3.jpg";
import Review4 from "../assets/Reviews/r4.jpg";
import Review5 from "../assets/Reviews/r5.jpg";
import Review6 from "../assets/Reviews/r6.jpg";
import { AppContext } from "../context/AppContext";

const Reviews = () => {
  const { isHindi } = useContext(AppContext);
  return (
    <div className="w-11/12 mx-auto max-w-[1280px]">
      <div className="w-[100%] py-10 flex flex-col justify-between">
        <h2 className="text-5xl mx-auto text-center font-bold text-yellow-600">
          {isHindi ? "हमारी समीक्षाएँ" : "Our Reviews"}
        </h2>
        <div className="bg-yellow-600 h-1 w-28 rounded-full mt-6 mx-auto"></div>
        <div className="w-full py-12 gap-10 md:gap-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center">
          <img
            src={Review3}
            alt=""
            className="shadow-lg rounded-3xl hover:scale-110 transition-all duration-300"
          />
          <img
            src={Review2}
            alt=""
            className="shadow-lg rounded-3xl hover:scale-110 transition-all duration-300 hover:shadow-2xl"
          />
          <img
            src={Review6}
            alt=""
            className="shadow-lg rounded-3xl hover:scale-110 transition-all duration-300 hover:shadow-2xl"
          />
          <img
            src={Review4}
            alt=""
            className="shadow-lg rounded-3xl hover:scale-110 transition-all duration-300 hover:shadow-2xl"
          />
          <img
            src={Review1}
            alt=""
            className="shadow-lg rounded-3xl hover:scale-110 transition-all duration-300 hover:shadow-2xl"
          />
          <img
            src={Review5}
            alt=""
            className="shadow-lg rounded-3xl hover:scale-110 transition-all duration-300 hover:shadow-2xl"
          />
        </div>
      </div>
    </div>
  );
};

export default Reviews;
