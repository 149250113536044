import product1 from "../assets/product1.jpg";
import product2 from "../assets/product2.jpg";
import product3 from "../assets/product3.jpg";
import product4 from "../assets/product4.jpg";
import FoxtailKhichdi from "../assets/Recipes/foxtail-millet-khichdi.jpg";
import FoxtailTikki from "../assets/Recipes/foxtail-millet-tikki.jpg";
import UnpolishedDaliya from "../assets/Recipes/unpolished-daliya.jpg";
import UnpolishedKhichdi from "../assets/Recipes/unpolished-khichdi.jpg";
import UnpolishedTikki from "../assets/Recipes/unpolished-tikki.jpg";
import UnpolishedBurger from "../assets/Recipes/unpolished-burger.jpg";
import PolishedKhichdi from "../assets/Recipes/polished-khichdi.jpg";
import PolishedHalwa from "../assets/Recipes/polished-halwa.jpg";
import PolishedKheer from "../assets/Recipes/polished-kheer.jpg";
import PolishedTikki from "../assets/Recipes/polished-tikki.jpg";
import PolishedAppe from "../assets/Recipes/polished-appe.jpg";

export const productDataEnglish = [
  {
    img: product1,
    cTitle: "Foxtail Millet",
    alt: "Foxtail Millet",
    title: "Foxtail Millet",
    priceHalf: 49,
    priceOne: 99,
    dTitle: "Foxtail millet (Highest Protein and Fiber content)",
    defination:
      'Foxtail millet, also known as Setaria italica, also known as "kangni" in hindi is a type of grass that has been a key part of Asian cuisine for centuries. This highly nutritious grain is often used as a food source.',
    benefits: "Benefits of consuming Foxtail Millet:",
    dPara: [
      {
        point: "Rich in iron and calcium.",
      },
      {
        point:
          "Helps to lower cholesterol levels leading to better cardiac health.",
      },
      {
        point: "Ultimate superfood for pregnant women.",
      },
      {
        point:
          "Good source of magnesium, which helps in maintaining healthy bones.",
      },
      {
        point: "Triggers Weight loss.",
      },
      {
        point: "Strengthens nervous system.",
      },
    ],
    recipeTitle: "Foxtail Millet (Kangni) Recipes",
    recipes: [
      {
        title: "Foxtail Millet (Kangni) Tikki",
        image: FoxtailTikki,
        ingredients: [
          "1 bowl yellow foxtail millet",
          "3 small potatoes",
          "3-4 green chilies",
          "half bowl ground peanuts",
          "salt/rock salt as per taste",
        ],
        instructions: [
          "Soak foxtail millet in water for 5-6 hours.",
          "Heat oil/groundnut oil in a pan, add cumin seeds, green chilies and cook.",
          "After it is cooked, add 1 bowl of foxtail millet to it and cook it.",
          "After it is cooked, add 4 bowls of water to it and cook for some time.",
          "After the water dries completely, cool it, mix it with mashed potatoes and make small tikkis.",
          "Heat oil/peanut oil in a frying pan and fry the tikkis.",
          "Serve hot with green chutney/tomato ketchup/ curd.",
        ],
      },
      {
        title: "Foxtail Millet (Kangni) Khichdi",
        image: FoxtailKhichdi,
        ingredients: [
          "1 bowl Foxtail Millet",
          "2 small potatoes",
          "3-4 green chilies",
          "half bowl ground peanuts",
          "salt/rock salt as per taste",
          "half teaspoon cumin",
        ],
        instructions: [
          "Soak Foxtail millet (Kangni) in water for 5-6 hours.",
          "Heat 1 teaspoon oil/peanut oil in a pan.",
          "Add cumin seeds, potatoes, green chilies and cook.",
          "Add salt/rock salt as per taste.",
          "Add millet in it and cook it lightly for some time.",
          "After that add 4 bowls of water and cook for some time.",
          "After the water dries completely, add peanuts and green coriander and keep it covered for 5-10 minutes.",
          "Serve hot.",
        ],
      },
    ],
  },
  {
    img: product2,
    cTitle: "Little Millet (Unpolished)",
    alt: "Little Millet (Unpolished)",
    title: "Little Millet (Unpolished)",
    priceHalf: 49,
    priceOne: 99,
    dTitle: "Little Millet (unpolished) (Highest Iron and Energy content)",
    defination:
      "Also known as African Millet. Identified in India as the fasting grain or उपवास के चावल.  Traditionally, first solid food introduced to babies also known as ‘Annaprash’. It is also considered as the best food for recovering from sickness because of its highest iron and energy content.",
    benefits: "Benefits of consuming Little Millet (Unpolished):",
    dPara: [
      {
        point: "Rich in Potent Antioxidants.",
      },
      {
        point: "Lower the Risk of Diabetes.",
      },
      {
        point: "Helps in improving respiratory conditions.",
      },
      {
        point: "Controls Obesity",
      },
      {
        point: "Manages Cardiovascular System.",
      },
    ],
    recipeTitle: "Little Millet (Unpolished) Recipes",
    recipes: [
      {
        title: "Unpolished Little Millet (Unpolished Mordhan/ Kutki Unpolished/ Sama/ Sawa) Burger",
        image: UnpolishedBurger,
        ingredients: [
          "Half bowl unpolished Little Millet",
          "green chilies",
          "cumin seeds",
          "oil",
          "carrots",
          "beans",
          "peas",
          "capsicum (finely chopped)",
          "peanuts (finely ground)",
          "salt as per taste",
          "chili flakes",
          "boiled potatoes",
        ],
        instructions: [
          " Add one spoon cumin and green chili in a pan and cook.",
          "After that add chopped carrots, capsicum, peas, beans and cook for some time.",
          "Then add half a bowl of unpolished little millet and one and a half cup of water and cook for some time.",
          "After cooking, cool down the millet.",
          "After it cools down, add mashed potatoes, 2 spoons of ground peanuts, chili flakes and make tikkis.",
          "Fry the tikkis in oi.",
          "Place the fried tikki in the middle of a burger bun, along with a chopped tomato, onion, cabbage, tomato ketchup, mint chutney etc. and serve.",
        ],
      },
      {
        title: "Little Millet (Polished) (Kutki Polished/ Sama/ Sawa) Tikki",
        image: UnpolishedTikki,
        ingredients: [
          "1 bowl little millet unpolished",
          "2-3 small potatoes",
          "finely ground peanuts",
          "chopped green chilies",
          "salt/rock salt",
        ],
        instructions: [
          "Add one spoon oil/groundnut oil in a pan.",
          "After the oil is hot, add cumin seeds, green chilies and ground peanuts and cook lightly.",
          "Now add 1 bowl of little millet and fry lightly.",
          "Add 3 bowls of water and cook it for some time.",
          "After the water dries completely, cool the cooked little millet.",
          "When little millet cools down, add mashed potatoes to it and make small tikkis out of it.",
          "Heat oil/groundnut oil in a frying pan.",
          "Fry the tikkis in it until cooked.",
          "Serve hot with green chutney/tomato sauce/curd.",
        ],
      },
      {
        title:
          "Unpolished Little Millet (Unpolished Mordhan/ Kutki Unpolished/ Sama/ Sawa) Khichdi",
        image: UnpolishedKhichdi,
        ingredients: [
          "One bowl of unpolished little millet",
          "green chilies",
          "finely ground peanuts",
          "salt/ rock salt",
          "cumin seeds",
        ],
        instructions: [
          "Heat oil/groundnut oil in a pan, add cumin seeds, potatoes, green chilies, salt/rock salt and cook.",
          "After that, add 1 bowl of little millet and fry it lightly.",
          "After that add 3 bowls of water and cook it.",
          "After the water dries completely, add ground peanuts and green coriander and serve hot.",
        ],
      },
      {
        title:
          "Unpolished Little Millet (Unpolished Mordhan/ Kutki Unpolished/ Sama/ Sawa) Daliya",
        image: UnpolishedDaliya,
        ingredients: [
          "One bowl of unpolished little millet",
          "ghee",
          "jaggery/sugar",
          "dry fruits",
        ],
        instructions: [
          "Heat some ghee in a pan.",
          "After the ghee is heated, add unpolished little millet in it and roast it.",
          "After the little millet is cooked, add 3 bowls of water to it.",
          "After the water dries completely, add jaggery/sugar to it and cook.",
          "After little millet is cooked, add dry fruits and serve hot.",
        ],
      },
    ],
  },
  {
    img: product3,
    cTitle: "Little Millet (Polished)",
    alt: "Little Millet (Polished)",
    title: "Little Millet (Polished)",
    priceHalf: 55,
    priceOne: 110,
    dTitle: "Little Millet (polished) (Highest Calcium and Protein content)",
    defination:
      "It is the polished version of little millet hence more refined. It is considered as the best food for adults and growing children because of its highest calcium and protein content. It is more often consumed in upwas/fast and can be consumed in usual diet as well.",
    benefits: "Benefits of consuming Little Millet (Polished):",
    dPara: [
      {
        point: "3 times more dietary fibre than white rice.",
      },
      {
        point: "Helps in building stamina and healthy blood production ",
      },
      {
        point: "Helps in growth and immunity.",
      },
      {
        point: "Helps the body in energy metabolism.",
      },
      {
        point: "Rich in B vitamins like B1 and Folate.",
      },
    ],
    recipeTitle: "Little Millet (Polished) Recipes",
    recipes: [
      {
        title: "Organic Little Millet (Polished) (Kutki Polished/ Sama/ Sawa) Appe",
        image: PolishedAppe,
        ingredients: [
          "1 bowl white mordhan",
          "green chili",
          "green coriander",
          "curry leaves",
          "salt",
          "cumin",
          "mustard",
          "oil",
          "curd",
          "carrot",
          "capsicum",
          "onion",
          "sweet soda",
        ],
        instructions: [
          "Grind a bowl of Mordhan coarsely in a mixer.",
          "Put half a bowl of curd, half a bowl of water, ground Mordhan, finely chopped capsicum, finely chopped carrots, finely chopped onions, green chillies in a vessel and keep it aside for one to one and a half hours.",
          "In a pan, heat 1 spoon oil, cumin seeds and curry leaves and add it to Mordhan.",
          "After this, add sweet soda equal to one-fourth of a teaspoon and mix it well.",
          "After the water dries completely, cool the cooked little millet.",
          "Put some oil in an appe mold, pour the mixture and cook it.",
          "Serve the prepared appe with green chutney or coconut chutney etc..",
        ],
      },
      {
        title: "Little Millet (Polished) (Kutki Polished/ Sama/ Sawa) Tikki",
        image: PolishedTikki,
        ingredients: [
          "1 bowl little millet (polished)",
          "2-3 small potatoes",
          "finely ground peanuts",
          "chopped green chilies",
          "salt/rock salt",
        ],
        instructions: [
          "Add one spoon oil/groundnut oil in a pan.",
          "After the oil is hot, add cumin seeds, green chilies and ground peanuts and cook lightly.",
          "Now add 1 bowl of little millet and fry lightly.",
          "Add 3 bowls of water and cook it for some time.",
          "After the water dries completely, cool the cooked little millet.",
          "When little millet cools down, add mashed potatoes to it and make small tikkis out of it.",
          "Heat oil/groundnut oil in a frying pan.",
          "Fry the tikkis in it until cooked.",
          "Serve hot with green chutney/tomato sauce/curd.",
        ],
      },
      {
        title: "Little Millet (polished) (Kutki Polished/ Sama/ Sawa) Kheer",
        image: PolishedKheer,
        ingredients: [
          "1 bowl little millet (polished)",
          "milk, sugar",
          "cardamom powder",
          "cashew nuts",
          "almonds",
          "raisins",
        ],
        instructions: [
          "Take milk in a vessel and boil it.",
          "Add little millet in it and cook it.",
          "Once little millet is cooked, add sugar to it and cook for some more time.",
          "After it is cooked, add cashew nuts, almonds, raisins and serve it hot.",
        ],
      },
      {
        title: "Little Millet (Polished) (Kutki Polished/ Sama/ Sawa) Khichdi",
        image: PolishedKhichdi,
        ingredients: [
          "1 bowl Little Millet (Polished)",
          "1 potato (finely chopped)",
          "peanuts (finely ground)",
          "chopped green chili",
          "salt/rock salt",
        ],
        instructions: [
          "Add one spoon oil/groundnut oil in a pan.",
          "After the oil is heated, cook cumin seeds and chopped potatoes in it.",
          "After it is cooked, add 1 bowl of little millet to it and cook for some time.",
          "Now add 3 bowls of water and cook it.",
          "When the water dries completely, add ground peanuts to it.",
          "Sprinkle green coriander on it and serve hot.",
        ],
      },
      {
        title: "Little Millet (polished) (Kutki Polished/ Sama/ Sawa) Halwa",
        image: PolishedHalwa,
        ingredients: [
          "1 bowl little millet (polished)",
          "milk, sugar",
          "ghee",
          "cardamom",
          "saffron",
          "cashew nuts",
          "almonds",
          "raisins",
        ],
        instructions: [
          "Take spoon of ghee in a pan.",
          "After the ghee is heated, add cashew nuts, almonds and raisins and fry them lightly and then take them out in a separate vessel.",
          "Grind little millet in a mixer and make it fine.",
          "Roast the grinded (fine) little millet in the remaining ghee.",
          "After the little millet is cooked, add 3 bowls of water to it and cook it.",
          "After the water dries, add sugar to it and mix well.",
          "Heat milk in a vessel, add saffron and halwa (cooked in step 6) and cook.",
          "After it is cooked, add dry fruits to it and serve it hot.",
        ],
      },
    ],
  },
  {
    img: product4,
    cTitle: "Unpolished Kodo Millet",
    alt: "Unpolished Kodo Millet",
    title: "Unpolished Kodo Millet",
    priceHalf: 49,
    priceOne: 89,
    dTitle: "Organic Unpolished Kodo Millet (Rich in niacin, pyridoxine, folic acid, and other minerals)",
    defination:
      "Kodo millet was domesticated in India almost 3000 years ago. It is reputed to be extremely hardy, drought resistant and grows on stony or gravelly soils which would not support other crops. It is relatively long in duration requiring four to six months to mature compared with two to four months for the other miIlets. Short duration varieties have now been developed. It is an annual tufted grass that grows up to 90 cm high. The grain may vary in colour from light red to dark grey. It has the highest dietary fiber among stall the millets.",
    dPara: [
      {
        point: "Prevents constipation.",
      },
      {
        point: "Strengthens nervous system.",
      },
      {
        point: "Helps in blood sugar control.",
      },
      {
        point: "Beneficial  for  post menopausal  women  suffering from  any metabolic diseases.",
      },
    ],
    recipeTitle: "Kodo Millet (Unpolished) Recipes (Coming Soon...)",
  },
];
